import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Dropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react'

const ActionStatusDropdown = ({ onSelect }) => {
  const [open, setOpen] = useState(false)
  const toggleHandler = () => {
    setOpen(!open)
  }

  const onSelectHandler = (selectedStatus) => {
    onSelect(selectedStatus)
  }

  return (
    <Dropdown
      open={open}
      toggle={toggleHandler}
      group
      className="mb-4 ml-4 action-dropdown"
    >
      <Button outline theme="secondary" className="status-container">
        Select action
      </Button>
      <DropdownToggle split outline theme="secondary" />
      <DropdownMenu>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onSelectHandler('pending')
          }}
        >
          <span className="status-signal status-pending" /> pending
        </DropdownItem>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onSelectHandler('accepted')
          }}
        >
          <span className="status-signal status-accepted" /> accepted
        </DropdownItem>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onSelectHandler('rejected')
          }}
        >
          <span className="status-signal status-rejected" />
          rejected
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionStatusDropdown

ActionStatusDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
}
