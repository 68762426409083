import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Card, CardTitle, CardBody, FormGroup } from 'shards-react'

const Question = ({ text, userName, submitted }) => {
  return (
    <Card
      style={{ width: '320px', marginRight: '1.5rem', marginBottom: '1.5rem' }}
    >
      <CardBody className="p-4">
        <CardTitle
          style={{
            marginBottom: '0',
            fontSize: '16px',
          }}
        >
          Participant: {userName} <br />
          Date: {moment(submitted).format('MM-DD-YYYY')}
        </CardTitle>

        <FormGroup>
          <p className="mb-0 mt-4">{text}</p>
        </FormGroup>
      </CardBody>
    </Card>
  )
}

export default Question

Question.propTypes = {
  text: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  submitted: PropTypes.instanceOf(Date).isRequired,
}
