import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle } from 'shards-react'
import * as shared from 'pericles-shared'
import { RegistrationEmailSentStatus } from 'components/message-status/registration-email-sent'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import AlertModal from '../../components/common/Modal'
import {
  USER_EMAIL_STORAGE_KEY,
  EMAIL_PATTERN,
  LOGIN_STATUS,
} from '../../constants'

const Home = () => {
  const [email, setEmail] = useState('')
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [requestStatus, setRequestStatus] = useState('pending')
  const [statusMessage, setStatusMessage] = useState()
  const [showModal, setShowModal] = React.useState(false)
  const [modalDescription, setModalDescription] = React.useState('')
  const [modalTitle, setModalTitle] = React.useState('')
  const toggle = () => setShowModal(!showModal)

  const readSession = () => {
    const user = shared.firebase.auth().currentUser

    if (user) {
      setLoggedIn(true)
    }
  }

  useEffect(() => {
    readSession()
  }, [])

  const sendSignInLink = async () => {
    const { origin } = window.location
    const url = `${origin}/check_login?e=${email}`
    const actionCodeSettings = { url, handleCodeInApp: true }
    await shared.firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        setRequestStatus('complete')
        setStatusMessage(RegistrationEmailSentStatus)
        window.localStorage.setItem(LOGIN_STATUS, 'complete')
        window.localStorage.setItem(USER_EMAIL_STORAGE_KEY, email)
      })
      .catch(() => {
        setRequestStatus('pending')
        setShowModal(true)
        setModalTitle('Failed')
        setModalDescription('Please try again.')
      })
  }

  const handlerOnRegister = async (event) => {
    event.preventDefault()
    sendSignInLink()
  }

  const handlerOnClickSignout = async () => {
    await shared.firebase.auth().signOut()
    window.location.reload()
    window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
    setLoggedIn(false)
  }

  return (
    <div>
      <AlertModal
        isOpen={showModal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
      <Card
        style={{
          width: '350px',
          position: 'absolute',
          marginTop: '50px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <CardBody>
          <CardTitle>Login</CardTitle>
          {!isLoggedIn ? (
            <>
              <p className="lead" />
              {requestStatus === 'pending' && (
                <div style={{ width: '100%', marginBottom: '15px' }}>
                  <AvForm onSubmit={handlerOnRegister}>
                    <AvField
                      name="email"
                      label="Email:"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="email"
                      errorMessage="Invalid email"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: EMAIL_PATTERN,
                        },
                      }}
                    />
                    <Button color="primary">Login</Button>
                  </AvForm>
                </div>
              )}

              {requestStatus === 'complete' && <div>{statusMessage}</div>}
            </>
          ) : (
            <Button onClick={handlerOnClickSignout}>Logout</Button>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default Home
