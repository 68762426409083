import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'

const LoggedOutLayout = ({ children, noNavbar, noFooter, noSidebar }) => (
  <Container fluid>
    <Row>
      <Col
        className="p-0"
        lg={{ size: 6, offset: 3 }}
        md={{ size: 6, offset: 3 }}
        sm="12"
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>
)

LoggedOutLayout.propTypes = {
  /**
   * Whether to display the sidebar or not
   */
  noSidebar: PropTypes.bool,
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
}

LoggedOutLayout.defaultProps = {
  noSidebar: true,
  noNavbar: true,
  noFooter: true,
}

export default LoggedOutLayout
