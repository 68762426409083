export default function() {
  return [
    {
      title: 'Event Control',
      to: '/',
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: '',
    },
    {
      title: 'Admin users',
      to: '/admin',
      htmlBefore: '<i class="material-icons">view_module</i>',
      htmlAfter: '',
    },
    {
      title: 'Registration',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/registration',
    },
    {
      title: 'Q&A',
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: '/q-and-a',
    },
    {
      title: 'Polls',
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: '/polls',
    },
    {
      title: 'Chat',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/chat',
    },
    {
      title: 'Restrict Access',
      htmlBefore: '<i class="material-icons">clear</i>',
      to: '/restrict',
    },
  ]
}
