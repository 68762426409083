import Fuse from 'fuse.js'

type Options = {
  shouldSort: boolean
  minMatchCharLength: number
  threshold: number
  distance: number
  keys: string[]
}

type SearchData = {
  firstName: string
  lastName: string
  email: string
  createdAt: string
}[]

const options: Options = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.3,
  distance: 100,
  keys: ['email', 'firstName', 'lastName'],
}

export const search = (input: string, data: SearchData) => {
  const fuse = new Fuse(data, options)
  return fuse.search(input)
}
