import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/shards-dashboards.1.1.0.min.css'
import './styles.css'

import { LoggedOutLayout, DefaultLayout } from 'layouts'
import Home from 'views/unauthenticated/Home'
import LogOut from 'views/unauthenticated/LogOut'
import AccessDenied from 'views/unauthenticated/AccessDenied'
import CheckLogin from 'views/unauthenticated/CheckLogin'
import FourZeroFour from 'views/unauthenticated/FourZeroFour'
import Registration from 'views/authenticated/Registration'
import QuestionsAnswers from 'views/authenticated/QuestionsAnswers'
import withTracker from './withTracker'
import protectedRoutes from './routing/protectedRoutes'
import ProtectedRouteHoc from './routing/ProtectedRouteHoc'

export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ''}>
    <div>
      <Switch>
        <Route
          path="/login"
          exact
          component={withTracker((props) => (
            <LoggedOutLayout {...props}>
              <Home {...props} />
            </LoggedOutLayout>
          ))}
        />
        <Route
          path="/logout"
          exact
          component={withTracker((props) => (
            <LoggedOutLayout {...props}>
              <LogOut {...props} />
            </LoggedOutLayout>
          ))}
        />
        <Route
          path="/check_login"
          component={withTracker((props) => (
            <LoggedOutLayout {...props}>
              <CheckLogin {...props} />
            </LoggedOutLayout>
          ))}
        />
        <Route
          path="/access_denied"
          component={withTracker((props) => (
            <LoggedOutLayout {...props}>
              <AccessDenied {...props} />
            </LoggedOutLayout>
          ))}
        />
        <Route
          path="/registration"
          component={withTracker((props) => (
            <DefaultLayout {...props}>
              <Registration {...props} />
            </DefaultLayout>
          ))}
        />
        <Route
          path="/q-and-a"
          component={withTracker((props) => (
            <DefaultLayout {...props}>
              <QuestionsAnswers {...props} />
            </DefaultLayout>
          ))}
        />
        {protectedRoutes.map((route, index) => {
          return (
            <ProtectedRouteHoc
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker((props) => (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              ))}
            />
          )
        })}
        <Route component={FourZeroFour} />
      </Switch>
    </div>
  </Router>
)
