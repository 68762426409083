// TODO: Implement api
import * as shared from 'pericles-shared'
import * as types from 'pericles-types'
// export const register = shared.callFunction<
//   types.SignUpInput,
//   types.SignUpResponse
// >('register')

// export const authenticate = shared.callFunction<
//   types.AuthenticateInput,
//   types.AuthenticateResponse
// >('authenticate')

export const addUsersToWhitelist = shared.callFunction<
  any,
  types.AddUserToWhitelistInput
>('addUsersToWhitelist')

export const pendingWhitelistUsers = shared.callFunction<any>(
  'pendingWhitelistUsers'
)

export const whitelistDomainUsers = shared.callFunction<any>(
  'whitelistDomainUsers'
)

export const addAdminRole = shared.callFunction<
  types.AddAdminRoleInput,
  types.AddAdminRoleResponse
>('addAdminRole')

export const updateWhitelistStatus = shared.callFunction<
  types.UpdateWhitelistStatusInput,
  types.DefaultResponse
>('updateWhitelistStatus')

export const updateWhitelistStatusList = shared.callFunction<
  types.UpdateWhitelistStatusListInput,
  types.DefaultResponse
>('updateWhitelistStatusList')
