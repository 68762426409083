import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

export const activePoll = shared.callFunction<
  types.ActivePollInput,
  types.DefaultResponse
>('activePoll')

export const deactivePoll = shared.callFunction<
  types.DeactivePollInput,
  types.DefaultResponse
>('deactivePoll')

export const deletePoll = shared.callFunction<
  types.DeletePollInput,
  types.DefaultResponse
>('deletePoll')

export const changePollQuestion = shared.callFunction<
  types.ChangePollQuestionInput,
  types.DefaultResponse
>('changePollQuestion')

export const changePollOptionAnswer = shared.callFunction<
  types.ChangePollOptionAnswerInput,
  types.DefaultResponse
>('changePollOptionAnswer')

export const addPollOption = shared.callFunction<
  types.AddPollOptionInput,
  types.AddPollOptionResponse
>('addPollOption')

export const deletePollOption = shared.callFunction<
  types.DeletePollOptionInput,
  types.DefaultResponse
>('deletePollOption')

export const getPolls = shared.callFunction<
  types.GetPollsInput,
  types.GetPollsResponse
>('getPolls')

export const addPoll = shared.callFunction<
  types.AddPollInput,
  types.AddPollResponse
>('addPoll')
