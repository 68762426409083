/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Form,
  FormInput,
} from 'shards-react'
import moment from 'moment'
import ReactFileReader from 'react-file-reader'
import * as shared from 'pericles-shared'
import * as auth from 'api/auth'
import { errorHandler, firebase } from 'pericles-shared'
import StatusDropdown from 'components/StatusDropdown'
import ActionStatusDropdown from 'components/ActionStatusDropdown'
import { updateWhitelistStatusList } from 'api/auth'
import AlertModal from 'components/common/Modal'
import extractNumbers from 'utils/extract-numbers'
import * as constants from '../../constants'
import PageTitle from '../../components/common/PageTitle'
import PaginationHandler from '../../components/common/Pagination'

const { arrayObjectsToCSV, useCollection, useDocument, fuego } = shared
const limit = 20

const Registration = () => {
  const [open, setOpen] = useState(false)
  const [checkedUsers, setCheckedUsers] = useState({})
  const [allCheckedStatus, setAllCheckedStatus] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const [actionsStatus, setActionStatus] = useState('complete')
  const [currentPage, setCurrentPage] = useState(1)
  const [eventId, setEventId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalDescription, setModalDescription] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const { data: events } = useCollection('events')
  const { data: event } = useDocument(`events/${eventId}`, { listen: true })
  const [data, setData] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [previousRegistrationCount, setPreviousRegistrationCount] = useState(0)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const defaultEvent = urlParams.get('event') ?? null
    if (defaultEvent) {
      setEventId(defaultEvent)
    }
  }, [])

  const fetchData = useCallback(async () => {
    if (eventId) {
      const registerCol = firebase.firestore().collection('register')
      let result
      setIsLoadingData(true)
      try {
        if (searchQueryValue) {
          result = await registerCol
            .where('eventId', '==', eventId)
            .orderBy('userEmail')
            .startAt(searchQueryValue)
            .endAt(`${searchQueryValue}~`)
            .get()
        } else {
          result = await registerCol
            .where('eventId', '==', eventId)
            .limit(limit * currentPage)
            .get()
        }

        if (!result.empty) {
          const dataResult = []
          result.docs.forEach((doc) => {
            dataResult.push({ ...doc.data(), id: doc.id })
          })
          setData(dataResult)
        } else {
          setData([])
        }
        setIsLoadingData(false)
      } catch (error) {
        errorHandler(error)
      }
    }
  }, [eventId, searchQueryValue, currentPage])

  useEffect(() => {
    if (event.registrationCount) {
      if (previousRegistrationCount <= event.registrationCount) {
        setPreviousRegistrationCount(event.registrationCount)
      } else {
        const validPage = Math.ceil(event.registrationCount / limit)
        setCurrentPage(validPage > 0 ? validPage : 0)
        fetchData()
      }
    }
  }, [event.registrationCount, fetchData, previousRegistrationCount])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, searchQueryValue, currentPage])

  const setModal = (title, description) => {
    setModalTitle(title)
    setModalDescription(description)
  }

  const handleFiles = (files) => {
    const reader = new FileReader()
    reader.onload = () => {
      setIsLoading(true)
      const readerResult = reader.result
      auth
        .addUsersToWhitelist({
          eventId,
          users: readerResult.split('\n'),
          restrictAccess: false,
          requestAccess: false,
          fromCSV: true,
        })
        .then(() => {
          setModal(constants.SUCCESS, constants.WHITELIST_SUCCESS)
          setShowModal(!showModal)
        })
        .catch((error) => {
          errorHandler(error)
          setModal(constants.FAILED, constants.WHITELIST_FAIL)
          setShowModal(!showModal)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    reader.readAsText(files[0])
  }

  const uploadCSVButton = (() => {
    return isLoading ? (
      <div className="ml-4 mt-2">
        <shared.Loader />
      </div>
    ) : (
      <ReactFileReader
        disabled={isLoading}
        handleFiles={handleFiles}
        fileTypes=".csv"
      >
        <Button disabled={isLoading} type="button" className="mb-4 ml-4">
          Upload CSV
        </Button>
      </ReactFileReader>
    )
  })()

  const dataPerPage = searchQueryValue
    ? data
    : data.slice(currentPage * limit - limit, currentPage * limit)

  const showExceededMessage = !isLoadingData && dataPerPage.length === 0

  const handlerOnClickExportToCSV = async () => {
    const csvData = []
    const { db } = fuego
    const { docs: registerDocs } = await db
      .collection('register')
      .where('eventId', '==', eventId)
      .get()
    registerDocs.forEach((doc) => {
      const {
        firstName,
        lastName,
        userEmail,
        jobTitle,
        isKaiserEmployee,
        company,
        location,
        hearAbout,
      } = doc.data()
      csvData.push({
        'First Name:': firstName,
        'Last Name:': lastName,
        'Email:': userEmail,
        'Job Title:': jobTitle,
        'Is Kaiser Employee?': isKaiserEmployee ? 'Yes' : 'No',
        'Company:': company,
        'Location:': location,
        'Where heard about the event:': hearAbout,
      })
    })

    const csvBinData = arrayObjectsToCSV(csvData)
    const urlBasedCsv = `data:text/plain;charset=UTF-8,${csvBinData}`

    const downloadAnchor = document.createElement('a')
    downloadAnchor.setAttribute('href', urlBasedCsv)
    downloadAnchor.setAttribute('download', 'registrations.csv')
    downloadAnchor.style.display = 'none'
    document.body.appendChild(downloadAnchor)
    downloadAnchor.click()
    document.body.removeChild(downloadAnchor)
  }

  const isAllRegistrationsChecked = useCallback(
    (newCheckedUsers) => {
      let condition = true
      const users = newCheckedUsers || checkedUsers
      dataPerPage.forEach((currentRegData) => {
        if (currentRegData && condition) {
          condition = users[currentRegData.userEmail]
            ? users[currentRegData.userEmail].check
            : false
        }
      })
      return condition
    },
    [checkedUsers, dataPerPage]
  )

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (dataPerPage.length > 0) {
      setAllCheckedStatus(isAllRegistrationsChecked())
    } else {
      setAllCheckedStatus(false)
    }
  }, [currentPage, dataPerPage, isAllRegistrationsChecked])

  const checkUserHandler = (registrationData) => {
    const previousValue = checkedUsers[registrationData.userEmail]
      ? checkedUsers[registrationData.userEmail].check
      : false

    const newCheckedUsers = {
      ...checkedUsers,
      [registrationData.userEmail]: {
        userEmail: registrationData.userEmail,
        check: !previousValue,
      },
    }

    setAllCheckedStatus(isAllRegistrationsChecked(newCheckedUsers))
    setCheckedUsers(newCheckedUsers)
  }

  const checkAllRegistersHandler = useCallback(
    (e) => {
      const newCheckedUsers = { ...checkedUsers }
      if (e.target.checked) {
        dataPerPage.forEach((registration) => {
          newCheckedUsers[registration.userEmail] = {
            userEmail: registration.userEmail,
            check: true,
          }
        })
      } else {
        dataPerPage.forEach((registration) => {
          newCheckedUsers[registration.userEmail] = {
            userEmail: registration.userEmail,
            check: false,
          }
        })
      }

      setCheckedUsers(newCheckedUsers)
      setAllCheckedStatus(e.target.checked)
    },
    [checkedUsers, dataPerPage]
  )

  const searchRegistrationsHandler = () => {
    setSearchQueryValue(searchValue)
    if (searchValue === '') {
      setCurrentPage(1)
    }
  }

  const onSelectActionHandler = async (actionStatus) => {
    const userEmailList = []
    const usersKeys = Object.keys(checkedUsers)
    usersKeys.forEach((key) => {
      if (checkedUsers[key].check) {
        userEmailList.push(key)
      }
    })

    setActionStatus('in_progress')
    try {
      await updateWhitelistStatusList({
        eventId,
        userEmailList,
        status: actionStatus,
      })
    } catch (error) {
      errorHandler(error, {
        others: { message: 'Error while trying to whitelist a list of users' },
      })

      setModal(constants.SOMETHING_WENT_WRONG, constants.ERROR_WHITELIST_USERS)
      setShowModal(true)
    }
    setActionStatus('complete')
  }

  const handleStatusChangeError = () => {
    // The error is being send to firebase already inside the dropdown component
    setModal(constants.SOMETHING_WENT_WRONG, constants.ERROR_WHITELIST_A_USER)
    setShowModal(true)
  }

  const showSelectAction = useMemo(() => {
    let condition = false
    const usersKeys = Object.keys(checkedUsers)
    usersKeys.forEach((key) => {
      if (!condition && checkedUsers[key].check) {
        condition = true
      }
    })

    return condition
  }, [checkedUsers])

  const refreshPage = () => {
    window.location.href = `?event=${eventId}`
  }

  const currentDropdownEventTitle = eventId
    ? `Event ${extractNumbers(eventId)[0]}`
    : 'Select an event'

  return (
    <div>
      <AlertModal
        isOpen={showModal}
        toggle={refreshPage}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 registration-header">
          <PageTitle
            sm="4"
            title="Registration"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />

          {eventId && (
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                searchRegistrationsHandler()
              }}
              className="flex-row mt-3 registration-search-form"
            >
              <FormInput
                placeholder="search email"
                className="mr-2"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button theme="secondary" onClick={searchRegistrationsHandler}>
                Search
              </Button>
            </Form>
          )}
        </Row>

        <div className="top-menu-buttons">
          <Row noGutters>
            <Dropdown
              open={open}
              toggle={() => setOpen(!open)}
              group
              className="mb-4"
            >
              <Button>{currentDropdownEventTitle}</Button>
              <DropdownToggle split />
              <DropdownMenu>
                {events.map((e) => {
                  const isValidEvent = extractNumbers(e.id)[0] !== -1
                  if (isValidEvent) {
                    return (
                      <DropdownItem key={e.id} onClick={() => setEventId(e.id)}>
                        Event {extractNumbers(e.id)[0]}
                      </DropdownItem>
                    )
                  }

                  return null
                })}
              </DropdownMenu>
            </Dropdown>

            {showSelectAction && (
              <>
                {actionsStatus === 'complete' ? (
                  <ActionStatusDropdown onSelect={onSelectActionHandler} />
                ) : (
                  <div className="ml-4 mt-2">
                    <shared.Loader />
                  </div>
                )}
              </>
            )}
          </Row>

          <Row noGutters>
            {eventId && (
              <Button onClick={handlerOnClickExportToCSV} className="mb-4 ml-4">
                Download CSV
              </Button>
            )}

            {eventId && uploadCSVButton}
          </Row>
        </div>

        {eventId ? (
          <Row>
            <Card className="w-100 mb-4">
              <CardHeader className="border-bottom table-title">
                <h6>Total Registrations: {event.registrationCount ?? 0}</h6>
              </CardHeader>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table table-hover mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0 table-header">
                        <input
                          type="checkbox"
                          className="ml-2"
                          checked={allCheckedStatus}
                          onChange={checkAllRegistersHandler}
                        />
                      </th>
                      <th scope="col" className="border-0 table-header">
                        Date added
                      </th>
                      <th scope="col" className="border-0 table-header">
                        First Name
                      </th>
                      <th scope="col" className="border-0 table-header">
                        Last Name
                      </th>
                      <th scope="col" className="border-0 table-header">
                        E-mail Address
                      </th>
                      <th scope="col" className="border-0 table-header">
                        Status
                      </th>
                    </tr>
                  </thead>
                  {!isLoadingData && (
                    <tbody>
                      {dataPerPage.map((registration, i) => {
                        const dateAddedRaw = registration.createdAt
                          ? new shared.firebase.firestore.Timestamp(
                              registration.createdAt.seconds,
                              registration.createdAt.nanoseconds
                            ).toDate()
                          : null

                        const dateAdded = dateAddedRaw
                          ? moment(dateAddedRaw).format('MM/DD/YYYY')
                          : 'N/D'

                        const isChecked = checkedUsers[registration.userEmail]
                          ? checkedUsers[registration.userEmail].check
                          : false

                        return (
                          <tr key={registration.id}>
                            <td className="table-data">
                              <input
                                type="checkbox"
                                className="ml-2"
                                checked={isChecked}
                                onChange={() => checkUserHandler(registration)}
                              />
                            </td>
                            <td className="table-data">{dateAdded}</td>
                            <td className="table-data">
                              {registration.firstName}
                            </td>
                            <td className="table-data">
                              {registration.lastName}
                            </td>
                            <td className="table-data">
                              {registration.userEmail}
                            </td>
                            <td className="table-data">
                              <StatusDropdown
                                eventId={eventId}
                                userEmail={registration.userEmail}
                                onError={handleStatusChangeError}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  )}
                </table>
                {isLoadingData && (
                  <div className="ml-auto mr-auto mt-2 w-full">
                    <shared.Loader />
                  </div>
                )}
                {showExceededMessage && (
                  <div className="footer-loader mt-4">
                    {searchQueryValue
                      ? constants.NO_DATA_FOUND
                      : constants.PAGE_LIMIT_EXCEEDED}
                  </div>
                )}
              </div>
              {!searchQueryValue && !isLoadingData ? (
                <CardFooter className="table-footer">
                  <PaginationHandler
                    activePage={currentPage}
                    itemsCountPerPage={limit}
                    totalItemsCount={event.registrationCount ?? 0}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </CardFooter>
              ) : null}
            </Card>
          </Row>
        ) : (
          <div className="footer-loader">
            <p>{constants.NO_EVENT_SELECTED}</p>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Registration
