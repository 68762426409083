import React from 'react'
import ReactDOM from 'react-dom'
import * as shared from 'pericles-shared'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from 'app'

ReactDOM.render(
  <shared.Provider>
    <App />
  </shared.Provider>,
  document.getElementById('root')
)
