import { useDocument, errorHandler } from 'pericles-shared'
import { useState, useEffect } from 'react'

const useUserStatus = (eventId, userEmail) => {
  const { data, error } = useDocument(
    `events/${eventId}/whitelist/${userEmail}`,
    {
      listen: true,
    }
  )

  const [status, setStatus] = useState('loading')

  useEffect(() => {
    if (error) {
      errorHandler(new Error(error), {
        eventId,
        userEmail,
        others: { hook: 'useUserStatus' },
      })
      setStatus('error')
    } else if (!data.requestAccess && !data.restrictAccess) {
      setStatus('accepted')
    } else if (!data.requestAccess && data.restrictAccess) {
      setStatus('rejected')
    } else if (data.requestAccess && data.restrictAccess) {
      setStatus('pending')
    }
  }, [data, error, eventId, userEmail])

  return status
}

export default useUserStatus
