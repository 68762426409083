import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Card,
  CardTitle,
  CardBody,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  FormInput,
} from 'shards-react'

import {
  activePoll,
  deactivePoll,
  deletePoll,
  changePollQuestion,
  changePollOptionAnswer,
  addPollOption,
  deletePollOption,
} from 'api/poll'

const OPTIONS_LIST = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const optionFactory = (answer = '', optionId = null) => {
  return {
    optionId,
    answer,
    votes: 0,
  }
}

const Poll = ({
  pollId,
  question: pollQuestion,
  options: pollOptions,
  activatedPollId,
  eventId,
  onDelete,
  onActivePollComplete,
  onDeactivePollComplete,
}) => {
  const [options, setOptions] = useState(
    pollOptions != null ? pollOptions : optionFactory()
  )
  const [question, setQuestion] = useState(pollQuestion)
  const [setupStatus, setSetupStatus] = useState('complete')

  const handlerOnAddNewOption = async () => {
    // Creates an option
    setSetupStatus('in_progress')
    try {
      const { optionId: newOptionId } = await addPollOption({
        eventId,
        pollId,
        answer: '',
      })

      const updatedOptions = [...options, optionFactory('', newOptionId)]

      setOptions(updatedOptions)
      setSetupStatus('complete')
    } catch (error) {
      console.error(error)
      setSetupStatus('error')
    }
  }

  const handlerOnRemoveOption = async (optionId) => {
    if (optionId) {
      // Removes option
      setSetupStatus('in_progress')
      try {
        await deletePollOption({
          eventId,
          pollId,
          optionId,
        })

        const updatedOptions = options.filter(
          (option) => option.optionId !== optionId
        )
        setOptions(updatedOptions)

        setSetupStatus('complete')
      } catch (error) {
        console.error(error)
        setSetupStatus('error')
      }
    }
  }

  const handlerOnChangeOptionValue = async (value, optionId) => {
    const currentOption = options.find((option) => option.optionId === optionId)

    const updatedOptions = options.map((option) => {
      if (option.optionId !== optionId) {
        return option
      }
      return { ...option, answer: value }
    })
    setOptions(updatedOptions)

    if (optionId && currentOption && value !== currentOption.answer) {
      // Updates option
      setSetupStatus('in_progress')
      try {
        await changePollOptionAnswer({
          eventId,
          pollId,
          optionId,
          answer: value,
        })

        setSetupStatus('complete')
      } catch (error) {
        console.error(error)
        setSetupStatus('error')
      }
    }

    if (!optionId) {
      // Creates an option
      setSetupStatus('in_progress')
      try {
        const { optionId: newOptionId } = await addPollOption({
          eventId,
          pollId,
          answer: value,
        })

        const optionWithNewItem = options.map((option) => {
          if (option.optionId != null) {
            return option
          }
          return optionFactory(value, newOptionId)
        })

        setOptions(optionWithNewItem)
        setSetupStatus('complete')
      } catch (error) {
        console.error(error)
        setSetupStatus('error')
      }
    }
  }

  const handlerOnChangeQuestion = async (event) => {
    setQuestion(event.target.value)

    // Updates question
    setSetupStatus('in_progress')
    try {
      await changePollQuestion({
        eventId,
        pollId,
        question: event.target.value,
      })
      setSetupStatus('complete')
    } catch (error) {
      console.error(error)
      setSetupStatus('error')
    }
  }

  const handlerOnDeletePull = async () => {
    if (pollId) {
      // Deletes Poll
      setSetupStatus('in_progress')
      try {
        await deletePoll({
          eventId,
          pollId,
        })
        setSetupStatus('complete')
        onDelete(pollId)
      } catch (error) {
        console.error(error)
        setSetupStatus('error')
      }
    } else {
      onDelete(pollId)
    }
  }

  const handlerActivatePoll = async () => {
    setSetupStatus('in_progress')

    // Activates Poll
    try {
      await activePoll({
        eventId,
        pollId,
      })

      setSetupStatus('complete')
      onActivePollComplete(pollId)
    } catch (error) {
      console.error(error)
      setSetupStatus('error')
    }
  }

  const handlerDeactivatePoll = async () => {
    // Deactivates Poll
    setSetupStatus('in_progress')
    try {
      await deactivePoll({
        eventId,
        pollId,
      })
      setSetupStatus('complete')
      onDeactivePollComplete()
    } catch (error) {
      console.error(error)
      setSetupStatus('error')
    }
  }

  return (
    <Card
      style={{ width: '320px', marginRight: '1.5rem', marginBottom: '1.5rem' }}
    >
      <Form>
        <CardBody className="p-4">
          <CardTitle
            style={{
              marginBottom: '0',
              fontSize: '16px',
            }}
          >{`${pollId ? `ID: ${pollId}` : '# New Pull'}`}</CardTitle>
          <div style={{ display: 'flex' }}>
            <p
              style={{
                marginRight: '0.25rem',
              }}
            >
              Status:
            </p>
            {setupStatus === 'complete' && activatedPollId !== pollId && (
              <p style={{ color: '#32cd32' }}>ready</p>
            )}
            {setupStatus === 'complete' && activatedPollId === pollId && (
              <p style={{ color: '#32cd32' }}>ready and activated</p>
            )}
            {setupStatus === 'in_progress' && <p>saving...</p>}
            {setupStatus === 'error' && (
              <p style={{ color: '#ff0000' }}>error on save data</p>
            )}
          </div>

          <FormGroup>
            <p className="font-medium mb-2">Poll Question</p>
            <FormInput
              placeholder="Question"
              onBlur={handlerOnChangeQuestion}
              defaultValue={question}
            />
          </FormGroup>
          <FormGroup>
            <p className="font-medium mb-2">Poll Answers</p>
            {options.map((option, index) => {
              return (
                <div
                  key={
                    option.pollId ??
                    `${OPTIONS_LIST[index]}_${moment(
                      new Date()
                    ).milliseconds()}`
                  }
                  className="flex"
                >
                  <FormInput
                    id={`option${OPTIONS_LIST[index]}`}
                    defaultValue={option.answer}
                    placeholder={`Option ${OPTIONS_LIST[index]}`}
                    onBlur={(event) => {
                      handlerOnChangeOptionValue(
                        event.target.value,
                        option.optionId
                      )
                    }}
                    className="mb-2"
                    style={{
                      display: 'initial',
                      width: '80%',
                    }}
                  />
                  {options.length > 1 && setupStatus === 'complete' ? (
                    <Button
                      onClick={() => {
                        handlerOnRemoveOption(option.optionId)
                      }}
                      theme="danger"
                      className="float-right"
                    >
                      &ndash;
                    </Button>
                  ) : (
                    <Button disabled theme="danger" className="float-right">
                      &ndash;
                    </Button>
                  )}
                </div>
              )
            })}
          </FormGroup>
          {setupStatus === 'complete' ? (
            <Button onClick={handlerOnAddNewOption}>
              &#43; Add answer option
            </Button>
          ) : (
            <Button disabled>&#43; Add answer option</Button>
          )}

          <FormGroup className="mt-4">
            <p className="font-medium mb-2">Poll Setup</p>
            <ButtonGroup style={{ width: '100%' }}>
              {setupStatus === 'complete' ? (
                <>
                  {activatedPollId === pollId ? (
                    <Button onClick={handlerDeactivatePoll}>Deactivate</Button>
                  ) : (
                    <Button onClick={handlerActivatePoll}>Activate</Button>
                  )}
                  <Button onClick={handlerOnDeletePull} theme="danger">
                    Delete
                  </Button>
                </>
              ) : (
                <>
                  {activatedPollId === pollId ? (
                    <Button disabled>Deactivate</Button>
                  ) : (
                    <Button disabled>Activate</Button>
                  )}
                  <Button disabled theme="danger">
                    Delete
                  </Button>
                </>
              )}
            </ButtonGroup>
          </FormGroup>
        </CardBody>
      </Form>
    </Card>
  )
}

export default Poll

Poll.propTypes = {
  pollId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  question: PropTypes.string.isRequired,
  activatedPollId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      votes: PropTypes.number,
    })
  ).isRequired,
  eventId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivePollComplete: PropTypes.func.isRequired,
  onDeactivePollComplete: PropTypes.func.isRequired,
}

Poll.defaultProps = {
  pollId: null,
}
