import React from 'react'
import * as shared from 'pericles-shared'
import {
  USER_EMAIL_STORAGE_KEY,
} from '../../constants'

const LogOut = () => {

  shared.firebase.auth().signOut()
  window.location = '/'
  window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
  
  return (
    <></>
  )
}

export default LogOut
