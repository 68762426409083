import React from 'react'
import RoundedBottomContainer from './rounded-bottom-container'

export const RegistrationEmailSentStatus: React.FC = () => (
  <RoundedBottomContainer>
    <p className="px-0 sm:px-12">
      Please check your email to verify your login.
    </p>
  </RoundedBottomContainer>
)
