import { firebase, errorHandler } from 'pericles-shared'

const useUpdateUserStatus = (eventId, userEmail, onStatusUpdated) => {
  const docRef = userEmail
    ? firebase.firestore().doc(`events/${eventId}/whitelist/${userEmail}`)
    : null

  /**
   * Set the user status
   * @param {'pending' | 'accepted' | 'rejected'} status
   */
  const updateUserStatus = async (status, onError) => {
    if (userEmail && status) {
      let requestAccess = true
      let restrictAccess = true

      if (status === 'accepted') {
        requestAccess = false
        restrictAccess = false
      } else if (status === 'rejected') {
        requestAccess = false
        restrictAccess = true
      }

      docRef
        .set(
          {
            requestAccess,
            restrictAccess,
          },
          { merge: true }
        )
        .then(() => {
          if (onStatusUpdated) {
            onStatusUpdated()
          }
        })
        .catch((error) => {
          errorHandler(error, {
            userEmail,
            eventId,
            others: {
              message: 'Error while trying to whitelist the user',
            },
          })

          if (onError) {
            onError(error)
          }
        })
    }
  }

  return updateUserStatus
}

export default useUpdateUserStatus
