import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormCheckbox,
  ListGroup,
  ListGroupItem,
  Row,
} from 'shards-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import * as shared from 'pericles-shared'
import PageTitle from '../../components/common/PageTitle'
import TextInput from '../../components/common/TextInput'

const RegistrationDetail = ({ match }) => {
  const {
    params: { id },
  } = match
  const { data, update } = shared.useDocument(`register/${id}`)
  const { add } = shared.useCollection(`register/${id}/change-history`)
  const [registration, setRegistration] = useState({
    firstName: '',
    lastName: '',
    userEmail: '',
    jobTitle: '',
    isKaiserEmployee: false,
    location: '',
    hearAbout: '',
    eventId: '',
  })

  useEffect(() => {
    setRegistration({
      ...registration,
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
      jobTitle: data.jobTitle,
      isKaiserEmployee: data.isKaiserEmployee,
      location: data.location,
      hearAbout: data.hearAbout,
      eventId: data.eventId,
      createdOn: data.createdOn,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleUpdate() {
    add(data)
    update({ ...registration, lastUpdated: new Date() })
  }

  const handleFirstNameChange = (firstName) =>
    setRegistration({ ...registration, firstName })
  const handleLastNameChange = (lastName) =>
    setRegistration({ ...registration, lastName })
  const handleUserEmailChange = (userEmail) =>
    setRegistration({ ...registration, userEmail })
  const handleJobTitleChange = (jobTitle) =>
    setRegistration({ ...registration, jobTitle })
  const handleKaiserEmployeeChange = () =>
    setRegistration({
      ...registration,
      isKaiserEmployee: !registration.isKaiserEmployee,
    })
  const handleLocationChange = (location) =>
    setRegistration({ ...registration, location })
  const handleSubmit = (e) => {
    e.preventDefault()
    handleUpdate()
  }

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Registration"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Link className="pb-4 pl-4" to="/registration">
            Go back
          </Link>
        </Row>
        <Row>
          <Col lg={12}>
            <Card small className="w-100 mb-4">
              <CardHeader className="border-bottom">
                <h6>Registration Details</h6>
              </CardHeader>
              <ListGroup>
                <ListGroupItem>
                  <Form>
                    <TextInput
                      label="First Name"
                      value={registration.firstName}
                      onChange={handleFirstNameChange}
                    />
                    <TextInput
                      label="Last Name"
                      value={registration.lastName}
                      onChange={handleLastNameChange}
                    />
                    <TextInput
                      label="E-mail"
                      value={registration.userEmail}
                      onChange={handleUserEmailChange}
                    />
                    <TextInput
                      label="Job Title"
                      value={registration.jobTitle}
                      onChange={handleJobTitleChange}
                    />
                    <FormCheckbox
                      checked={registration.isKaiserEmployee}
                      onChange={handleKaiserEmployeeChange}
                    >
                      Kaiser Employee
                    </FormCheckbox>
                    <TextInput
                      label="Location"
                      value={registration.location}
                      onChange={handleLocationChange}
                    />
                    <TextInput
                      label="Hear About"
                      value={registration.hearAbout}
                      disabled
                    />
                    <TextInput
                      label="Event ID"
                      value={registration.eventId}
                      disabled
                    />
                    <TextInput
                      label="Registration Date"
                      value={moment(
                        registration.createdOn?.seconds * 1000
                      ).format('YYYY-MM-DDTHH:mm')}
                      disabled
                    />
                    <Button onClick={handleSubmit}>
                      Update
                    </Button>
                  </Form>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RegistrationDetail
