import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Dropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react'

import { useCollection, Loader, firebase } from 'pericles-shared'
import { getQuestions } from 'api/qanda'
import PageTitle from 'components/common/PageTitle'
import Question from 'components/Question'
import extractNumbers from 'utils/extract-numbers'

const { firestore } = firebase

const QuestionsAnswers = () => {
  const [open, setOpen] = useState(false)
  const { data: events } = useCollection('events')
  const [eventId, setEventId] = useState()
  const [serverQAData, setServerQAData] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState('pending')

  useEffect(() => {
    const fetchQAData = async () => {
      setFetchDataStatus('in_progress')
      const qaData = await getQuestions({
        eventId,
      })

      setServerQAData(qaData.questions)
      setFetchDataStatus('complete')
    }

    if (eventId) {
      fetchQAData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const handleOnSelectDropdownItem = (selectedEventId) => {
    setEventId(selectedEventId)
  }

  const currentDropdownEventTitle = eventId
    ? `Event ${extractNumbers(eventId)[0]}`
    : 'Select an event'

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Q&A"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>

        <Row noGutters>
          <Dropdown
            open={open}
            toggle={() => setOpen(!open)}
            group
            className="mb-4"
          >
            <Button>{currentDropdownEventTitle}</Button>
            <DropdownToggle split />
            <DropdownMenu>
              {events.map((e) => {
                const isValidEvent = extractNumbers(e.id)[0] !== -1
                if (isValidEvent) {
                  return (
                    <DropdownItem
                      key={e.id}
                      onClick={() => handleOnSelectDropdownItem(e.id)}
                    >
                      Event {extractNumbers(e.id)[0]}
                    </DropdownItem>
                  )
                }

                return null
              })}
            </DropdownMenu>
          </Dropdown>
        </Row>

        <Row noGutters className="mb-4">
          {serverQAData.map(({ text, userName, submitted, questionId }) => {
            const coveredSubmittedData = submitted ?? {
              _seconds: 0,
              _nanoseconds: 0,
            }
            const {
              _seconds: seconds,
              _nanoseconds: nanoseconds,
            } = coveredSubmittedData
            const submittedDate = new firestore.Timestamp(
              seconds,
              nanoseconds
            ).toDate()

            if (text) {
              return (
                <Question
                  key={questionId}
                  text={text}
                  userName={userName}
                  submitted={submittedDate}
                />
              )
            }
            return null
          })}
        </Row>

        {fetchDataStatus === 'in_progress' && (
          <div
            className="m-auto"
            style={{ width: 'fit-content', padding: '98px' }}
          >
            <Loader />
          </div>
        )}
      </Container>
    </div>
  )
}

export default QuestionsAnswers
