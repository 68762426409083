import React from 'react'
import { Button } from 'shards-react'
import * as shared from 'pericles-shared'
import {
  USER_EMAIL_STORAGE_KEY,
} from '../../constants'
import { Card, CardBody, CardTitle } from "shards-react";

const AccessDenied = () => {

  const handlerOnClickSignout = async () => {
    await shared.firebase.auth().signOut()
    window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
    window.location = '/'
  }

  return (
    <div>
        <Card style={{ 
          width: "350px" ,
          position: "absolute",
          marginTop: "50px",
          left: "50%",
          transform: "translateX(-50%)"
        }}>
        <CardBody>
          <CardTitle>Access Denied</CardTitle>
            <p>You do not have permission to access this resource. 
            Please contact an administrator for assistance.</p>
            <p>If this is your first time logging in, you may need 
              to log out and log back in to gain admin access.</p>
            <Button
              onClick={ handlerOnClickSignout }
            >
              Logout
            </Button>
        </CardBody>
      </Card>
    </div>
  )
}

export default AccessDenied
