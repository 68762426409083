import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Row } from 'shards-react'
import * as shared from 'pericles-shared'
import AlertModal from '../../components/common/Modal'
import { USER_EMAIL_STORAGE_KEY, ID_TOKEN } from '../../constants'

const { firebase } = shared

const CheckLogin = ({ history }) => {
  const [showModal, setShowModal] = React.useState(false)
  const [modalDescription, setModalDescription] = React.useState('')
  const [modalTitle, setModalTitle] = React.useState('')
  const toggle = () => setShowModal(!showModal)

  // Complete the Sign In process: When user clicks on the login link
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const checkSignIn = () => {
      let email =
        window.localStorage.getItem(USER_EMAIL_STORAGE_KEY) ??
        urlParams.get('e')

      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
            history.push('/')
          })
          .catch(() => {
            window.localStorage.removeItem(ID_TOKEN)
            setShowModal(true)
            setModalTitle('Error')
            setModalDescription('Something went wrong please try again.')
          })
      } else {
        setShowModal(true)
        setModalTitle('Email not found')
        setModalDescription(
          'Your email is not present in our system. Please return to the home page to log in.'
        )
      }
    }

    checkSignIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <AlertModal
        isOpen={showModal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
      <Container fluid className="main-content-container px-4">
        <Row>
          <div className="w-screen h-screen bg-white">
            <div className="mt-modal-center">
              <shared.Loader />
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(CheckLogin)
