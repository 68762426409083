import React from 'react'
// Layout Types
import { DefaultLayout } from '../layouts'

// Route Views
import RestrictUsers from '../views/authenticated/RestrictUsers'
import Admin from '../views/authenticated/Admin'
import EventControl from '../views/authenticated/EventControl'
import Registration from '../views/authenticated/Registration'
import RegistrationDetail from '../views/authenticated/RegistrationDetail'
import Chat from '../views/authenticated/Chat'
import Polls from '../views/authenticated/Polls'
import QuestionsAnswers from '../views/authenticated/QuestionsAnswers'
import Home from '../views/unauthenticated/Home'
import CheckLogin from '../views/unauthenticated/CheckLogin'

const protectedRoutes = [
  {
    path: '/',
    layout: DefaultLayout,
    name: 'event-overview',
    component: EventControl,
    exact: true,
    public: false,
    main: (props) => <EventControl {...props} />,
  },
  {
    path: '/registration/:id',
    name: 'registration-detail',
    layout: DefaultLayout,
    component: RegistrationDetail,
    public: false,
    exact: true,
    main: (props) => <RegistrationDetail {...props} />,
  },
  {
    path: '/registration',
    layout: DefaultLayout,
    component: Registration,
    name: 'registration',
    public: false,
    main: (props) => <Registration {...props} />,
    exact: true,
    routes: [
      // {
      //   path: '/:id',
      //   name: 'registration-detail',
      //   layout: DefaultLayout,
      //   component: RegistrationDetail,
      //   public: false,
      //   exact: true,
      //   main: (props) => <RegistrationDetail {...props} />,
      // },
    ],
  },
  {
    path: '/polls',
    layout: DefaultLayout,
    component: Polls,
    public: false,
    exact: true,
    name: 'polls',
    main: (props) => <Polls {...props} />,
  },
  {
    path: '/q-and-a',
    layout: DefaultLayout,
    component: QuestionsAnswers,
    name: 'q-and-a',
    exact: true,
    public: false,
    main: (props) => <QuestionsAnswers {...props} />,
  },
  {
    path: '/chat',
    layout: DefaultLayout,
    component: Chat,
    name: 'chat',
    public: false,
    exact: true,
    main: (props) => <Chat {...props} />,
  },
  {
    path: '/restrict',
    layout: DefaultLayout,
    component: RestrictUsers,
    name: 'restrict',
    public: false,
    exact: true,
    main: (props) => <RestrictUsers {...props} />,
  },
  {
    path: '/admin',
    layout: DefaultLayout,
    component: Admin,
    name: 'admin',
    public: false,
    exact: true,
    main: (props) => <Admin {...props} />,
  },
  {
    path: '/login',
    exact: true,
    layout: DefaultLayout,
    component: Home,
    name: 'home',
    main: (props) => <Home {...props} />,
    public: true,
  },
  {
    path: '/check_login',
    layout: DefaultLayout,
    component: CheckLogin,
    name: 'check-login',
    exact: true,
    main: (props) => <CheckLogin {...props} />,
    public: true,
  },
]

export default protectedRoutes
