import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Button, FormGroup, FormInput, InputGroup, InputGroupAddon } from 'shards-react';

const DatetimeInput = ({ title, value, onChange }) => {
  const TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

  const [datetime, setDatetime] = useState(moment(value).format(TIME_FORMAT));

  useEffect(() => {
    if (value) setDatetime(moment(value).format(TIME_FORMAT));
  }, [value]);

  return (
    <FormGroup>
      <strong className="text-muted d-block mb-3">{title}</strong>
      <InputGroup>
        <FormInput
          value={datetime}
          type="datetime-local"
          onChange={(e) => setDatetime(e.target.value)}
        />
        <InputGroupAddon type="append">
          <Button theme="primary" onClick={() => onChange(datetime)}>
            Update
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

export default DatetimeInput;
