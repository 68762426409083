import React from "react";
import { ButtonGroup, Button } from "shards-react";


const EventStateToggle = (props) => {
  
  const clickHandler = (newEventState) => {
    props.changeEventState(newEventState)
  }

  const getButtonState = (targetState) => {
    return props.eventState === targetState ? 'primary' : 'white'
  }

  
  return(
    <ButtonGroup className="mb-3">
      <Button onClick={ () => clickHandler('pre') } theme={ getButtonState('pre') }>Pre</Button>
      <Button onClick={ () => clickHandler('live') } theme={ getButtonState('live') }>Live</Button>
      <Button onClick={ () => clickHandler('post') } theme={ getButtonState('post') }>Post</Button>
    </ButtonGroup>
  )

}

export default EventStateToggle;
