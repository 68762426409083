import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Container,
  Row,
  Dropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react'

import { useCollection, Loader } from 'pericles-shared'
import { getPolls, addPoll } from 'api/poll'
import PageTitle from 'components/common/PageTitle'
import Poll from 'components/Poll'
import extractNumbers from 'utils/extract-numbers'

const Polls = () => {
  const [open, setOpen] = useState(false)
  const { data: events } = useCollection('events')
  const [eventId, setEventId] = useState()
  const [serverPollsData, setServerPollsData] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState('pending')
  const [activatedPoll, setActivatedPoll] = useState('')

  useEffect(() => {
    const fetchPollsData = async () => {
      setFetchDataStatus('in_progress')
      const pollsData = await getPolls({
        eventId,
      })

      const currentActivatedPoll = pollsData.polls.find(
        (poll) => poll.activated
      )
      if (currentActivatedPoll) {
        setActivatedPoll(currentActivatedPoll.pollId)
      }

      setServerPollsData(pollsData.polls)
      setFetchDataStatus('complete')
    }

    if (eventId) {
      fetchPollsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const handleOnSelectDropdownItem = (selectedEventId) => {
    setEventId(selectedEventId)
  }

  const handlerOnClickNewPoll = async () => {
    // Creates a Poll
    setFetchDataStatus('creating_poll')
    try {
      const { success, pollId, message } = await addPoll({
        eventId,
      })

      if (success) {
        setFetchDataStatus('complete')

        const updatedServerPollsData = [
          {
            pollId,
            question: '',
            activated: false,
            options: [
              {
                optionId: null,
                answer: '',
                votes: 0,
              },
            ],
          },
          ...serverPollsData,
        ]

        setServerPollsData(updatedServerPollsData)
      } else {
        alert(message)
      }
    } catch (error) {
      alert('Something went wrong!')
      setFetchDataStatus('complete')
    }
  }

  const handlerOnRemovePoll = (pollId) => {
    const updatedServerPollsData = serverPollsData.filter(
      (pollData) => pollData.pollId !== pollId
    )
    setServerPollsData(updatedServerPollsData)
  }

  const handlerOnActivePollComplete = (pollId) => {
    setActivatedPoll(pollId)
  }

  const handlerOnDeactivePollComplete = () => {
    setActivatedPoll('')
  }

  const currentDropdownEventTitle = eventId
    ? `Event ${extractNumbers(eventId)[0]}`
    : 'Select an event'

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Polls"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>

        <Row noGutters>
          <Dropdown
            open={open}
            toggle={() => setOpen(!open)}
            group
            className="mb-4"
          >
            <Button>{currentDropdownEventTitle}</Button>
            <DropdownToggle split />
            <DropdownMenu>
              {events.map((e) => {
                const isValidEvent = extractNumbers(e.id)[0] !== -1
                if (isValidEvent) {
                  return (
                    <DropdownItem
                      key={e.id}
                      onClick={() => handleOnSelectDropdownItem(e.id)}
                    >
                      Event {extractNumbers(e.id)[0]}
                    </DropdownItem>
                  )
                }

                return null
              })}
            </DropdownMenu>
          </Dropdown>

          {eventId && fetchDataStatus !== 'creating_poll' ? (
            <Button onClick={handlerOnClickNewPoll} className="mb-4 ml-4">
              New Poll
            </Button>
          ) : (
            <>
              {eventId && (
                <div
                  style={{
                    margin: '0.5rem',
                    marginLeft: '1rem',
                  }}
                >
                  <Loader />
                </div>
              )}
            </>
          )}
        </Row>

        <Row noGutters className="mb-4">
          {eventId && (
            <>
              {serverPollsData.map((pollData) => (
                <Poll
                  key={pollData.pollId ?? moment(new Date()).seconds()}
                  activatedPollId={activatedPoll}
                  onActivePollComplete={handlerOnActivePollComplete}
                  onDeactivePollComplete={handlerOnDeactivePollComplete}
                  onDelete={handlerOnRemovePoll}
                  eventId={eventId}
                  {...pollData}
                />
              ))}
            </>
          )}
        </Row>

        {fetchDataStatus === 'in_progress' && (
          <div
            className="m-auto"
            style={{ width: 'fit-content', padding: '98px' }}
          >
            <Loader />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Polls
