import React, { useState } from 'react'
import {
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap'
import * as auth from 'api/auth'
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from 'shards-react'
import * as shared from 'pericles-shared'

const AddEditAdminModal = ({ isOpen, toggle, updateData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }
  const [accessLevel, setAccessLevel] = useState(null)
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [errorDisplay, setErrorDisplay] = useState(null)

  const adminAccessLevels = [
    { id: 0, name: 'Superadmin' },
    { id: 1, name: 'Supermanager' },
    { id: 2, name: 'Manager' },
    { id: 3, name: 'Editor' },
    { id: 4, name: 'Moderator' },
    { id: 5, name: 'Registration manager' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    setEmailDisabled(true)
    setIsLoading(true)
    auth
      .addAdminRole({ email, accessLevel })
      .then((resp) => {
        if (resp.errors) {
          setIsLoading(false)
          setEmailDisabled(false)
          setErrorDisplay('Error adding user')
        } else {
          setIsLoading(false)
          setEmail('')
          setEmailDisabled(false)
          toggle()
          updateData()
        }
      })
      .catch(() => {
        setIsLoading(false)
        setEmailDisabled(false)
        setErrorDisplay('Error adding user')
      })
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setEmail('')
    setAccessLevel(null)
    toggle()
    updateData()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Admin User</ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <InputGroup>
                <Label for="email" className="mr-sm-2">
                  Email:
                </Label>
                <Input
                  disabled={emailDisabled}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="#email"
                  type="email"
                  name="email"
                  placeholder="email"
                />
              </InputGroup>
              <Label for="email" className="mr-sm-2">
                Permissions level:
              </Label>

              <Dropdown
                className="mb-2 mr-sm-2 mb-sm-0"
                open={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                group
              >
                <Button outline theme="secondary" onClick={toggleDropdown}>
                  {accessLevel !== null && accessLevel >= 0
                    ? adminAccessLevels[accessLevel].name
                    : 'Select Access Level'}
                </Button>
                <DropdownToggle outline theme="secondary" split />
                <DropdownMenu>
                  {adminAccessLevels.map((e) => (
                    <DropdownItem
                      key={e.id}
                      onClick={() => setAccessLevel(e.id)}
                    >
                      {e.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
            {errorDisplay !== null && <p>{errorDisplay}</p>}
            <ModalFooter>
              <Button disabled={!email.length || emailDisabled}>Add</Button>
              <Button outline theme="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          <shared.Loader />
        )}
      </ModalBody>
    </Modal>
  )
}

export default AddEditAdminModal
