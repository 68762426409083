import React from 'react'
import { Nav } from 'shards-react'

import * as shared from 'pericles-shared'
import SidebarNavItem from './SidebarNavItem'
import { Store } from '../../../flux'

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
    }

    this.onChange = this.onChange.bind(this)
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    Store.addChangeListener(this.onChange)
  }

  // eslint-disable-next-line react/sort-comp
  componentWillUnmount() {
    Store.removeChangeListener(this.onChange)
  }

  async componentDidMount() {
    try {
      const claims = await shared.getUserClaims()
      if (claims.accessLevel !== 0) {
        this.setState({
          navItems: Store.getSidebarItems().slice(2, 4),
        })
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn('failed to get user claims')
    }
  }

  onChange() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      navItems: Store.getSidebarItems(),
    })
  }

  render() {
    const { navItems: items } = this.state
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items &&
            // eslint-disable-next-line react/no-array-index-key
            items.map((item, idx) => <SidebarNavItem key={idx} item={item} />)}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems
