import React from 'react'

import { FormInput, FormTextarea, FormGroup } from 'shards-react'

const TextInput = ({
  id = '',
  label,
  value,
  textarea,
  placeholder,
  onChange,
  ...props
}) => {
  function renderInput() {
    if (textarea) {
      return (
        <FormTextarea
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      )
    }

    return (
      <FormInput
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
    )
  }
  return (
    <FormGroup>
      <label htmlFor={id}>{label}</label>
      {renderInput()}
    </FormGroup>
  )
}

export default TextInput
