/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Form,
  ListGroup,
  ListGroupItem,
} from 'shards-react'
import { CustomInput } from 'reactstrap'

import * as shared from 'pericles-shared'

import PageTitle from '../../components/common/PageTitle'
import EventStateToggle from '../../components/event-control/EventStateToggle'
import DatetimeInput from '../../components/common/DatetimeInput'
import TextInput from '../../components/common/TextInput'

const { useDocument } = shared

const EventControl = () => {
  const { data, update: updateEventState, error } = useDocument(
    `events/event2/EventProperties/EventState`,
    {
      listen: true,
    }
  )
  const { data: eventMetaData, update: updateEventMeta } = useDocument(
    `events/event2/EventProperties/EventMeta`,
    {
      listen: true,
      suspense: true,
    }
  )
  const [eventState, setEventState] = useState(data.currentState)
  const [eventMeta, setEventMeta] = useState({
    title: eventMetaData.title,
    description: eventMetaData.description,
    url: eventMetaData.url,
    location: eventMetaData.location,
    twitter: eventMetaData.description,
    facebook: eventMetaData.facebook,
    instagram: eventMetaData.instagram,
    linkedIn: eventMetaData.linkedIn,
    yammer: eventMetaData.yammer,
  })
  const [eventStart, setEventStart] = useState(new Date())
  const [eventEnd, setEventEnd] = useState(new Date())

  useEffect(() => {
    if (data) {
      setEventStart(data.eventStart.toDate())
      setEventEnd(data.eventEnd.toDate())

      if (eventState !== data.currentState) setEventState(data.currentState)
    }
  }, [data, eventState])

  if (error) {
    console.log('err:', error)
  }

  const changeEventState = (newEventState) => {
    updateEventState({ currentState: newEventState })
  }

  const toggleTechnicalDifficultiesMessage = () => {
    updateEventState({ showError: !data.showError })
  }

  const updateEventTime = (label, value) => {
    updateEventState({ [label]: new Date(value) })
  }

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Event Control"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg={4} className="mb-4">
            {/* Event State */}
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Event State</h6>
              </CardHeader>

              {/* State control */}
              <ListGroup flush>
                <ListGroupItem className="px-3">
                  <Form>
                    <strong className="text-muted d-block mb-3">
                      Current State
                    </strong>
                    <EventStateToggle
                      eventState={eventState}
                      changeEventState={changeEventState}
                    />
                  </Form>
                </ListGroupItem>
              </ListGroup>

              {eventState === 'live' && (
                <ListGroup flush>
                  <ListGroupItem>
                    <div className="pl-4">
                      <CustomInput
                        type="switch"
                        id="technicalDifficulties"
                        name="Technical Difficulties"
                        label="Show Technical Difficulties Message"
                        checked={data?.showError}
                        onChange={toggleTechnicalDifficultiesMessage}
                        className="pl-0"
                      />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              )}

              {/* Start Datetime */}
              <ListGroup flush>
                <ListGroupItem className="px-3">
                  <Form>
                    <DatetimeInput
                      title="Event Start"
                      value={eventStart}
                      onChange={(v) => updateEventTime('eventStart', v)}
                    />
                    <DatetimeInput
                      title="Event End"
                      value={eventEnd}
                      onChange={(v) => updateEventTime('eventEnd', v)}
                    />
                  </Form>
                </ListGroupItem>
              </ListGroup>
              {/* Auto-start */}
            </Card>
          </Col>
          <Col lg={4} className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Event Meta</h6>
              </CardHeader>
              <ListGroup>
                <ListGroupItem>
                  <Form>
                    <TextInput
                      id="title"
                      label="Title"
                      value={eventMeta.title}
                      onChange={(title) =>
                        setEventMeta({ ...eventMeta, title })
                      }
                    />
                    <TextInput
                      id="description"
                      label="Description"
                      value={eventMeta.description}
                      onChange={(description) =>
                        setEventMeta({ ...eventMeta, description })
                      }
                      textarea
                    />
                    <TextInput
                      id="location"
                      label="Location"
                      value={eventMeta.location}
                      onChange={(location) =>
                        setEventMeta({ ...eventMeta, location })
                      }
                    />
                    <TextInput
                      id="url"
                      label="URL"
                      value={eventMeta.url}
                      onChange={(url) => setEventMeta({ ...eventMeta, url })}
                    />
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        updateEventMeta({ ...eventMeta })
                      }}
                    >
                      Update
                    </Button>
                  </Form>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col lg={4} className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Social Media</h6>
              </CardHeader>
              <ListGroup>
                <ListGroupItem>
                  <Form>
                    <TextInput
                      id="twitter"
                      label="Twitter"
                      value={eventMeta.twitter}
                      onChange={(twitter) =>
                        setEventMeta({ ...eventMeta, twitter })
                      }
                    />
                    <TextInput
                      id="facebook"
                      label="Facebook"
                      value={eventMeta.facebook}
                      onChange={(facebook) =>
                        setEventMeta({ ...eventMeta, facebook })
                      }
                    />
                    <TextInput
                      id="instagram"
                      label="Instagram"
                      value={eventMeta.instagram}
                      onChange={(instagram) =>
                        setEventMeta({ ...eventMeta, instagram })
                      }
                    />
                    <TextInput
                      id="linkedIn"
                      label="linkedIn"
                      value={eventMeta.linkedIn}
                      onChange={(linkedIn) =>
                        setEventMeta({ ...eventMeta, linkedIn })
                      }
                    />
                    <TextInput
                      id="yammer"
                      label="Yammer"
                      value={eventMeta.yammer}
                      onChange={(yammer) =>
                        setEventMeta({ ...eventMeta, yammer })
                      }
                    />
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        updateEventMeta({ ...eventMeta })
                      }}
                    >
                      Update
                    </Button>
                  </Form>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EventControl
