import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Dropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react'
import useUserStatus from 'hooks/useUserStatus'
import useUpdateUserStatus from 'hooks/useUpdateUserStatus'

const StatusDropdown = ({ eventId, userEmail, onError }) => {
  const [open, setOpen] = useState(false)
  const status = useUserStatus(eventId, userEmail)
  const updateUserStatus = useUpdateUserStatus(eventId, userEmail)
  const toggleHandler = () => {
    setOpen(!open)
  }

  const onChangeHandler = (selectedStatus) => {
    updateUserStatus(selectedStatus, onError)
  }

  return (
    <Dropdown open={open} toggle={toggleHandler} group>
      <Button outline theme="secondary" className="status-container">
        <span className={`status-signal status-${status}`} /> {status}
      </Button>
      <DropdownToggle split outline theme="secondary" />
      <DropdownMenu>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onChangeHandler('pending')
          }}
        >
          <span className="status-signal status-pending" /> pending
        </DropdownItem>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onChangeHandler('accepted')
          }}
        >
          <span className="status-signal status-accepted" />
          accepted
        </DropdownItem>
        <DropdownItem
          className="status-item-container"
          onClick={() => {
            onChangeHandler('rejected')
          }}
        >
          <span className="status-signal status-rejected" />
          rejected
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusDropdown

StatusDropdown.propTypes = {
  eventId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
}
