/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import Pagination from 'react-js-pagination'

const PaginationHandler = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onChange,
}) => {
  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      hideDisabled
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={pageRangeDisplayed}
      onChange={onChange}
      itemClass="page-item"
      linkClass="page-link"
    />
  )
}

export default PaginationHandler
