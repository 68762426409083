import React from 'react'
import { Container, Row } from 'shards-react'
import { Jumbotron } from 'reactstrap'

const FourZeroFour = () => {

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Jumbotron>
          <h1 className="display-3">404</h1>
          <p>Page not found</p>
        </Jumbotron>
        <Row />
      </Container>
    </div>
  )
}

export default FourZeroFour
