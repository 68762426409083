export const USER_EMAIL_STORAGE_KEY = 'userEmail'
export const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'
export const ID_TOKEN = 'idToken'
export const LOGIN_STATUS = 'loginStatus'
export const SUCCESS = 'Success'
export const FAILED = 'Failed'
export const WHITELIST_SUCCESS = 'Successfully whitelisted user(s)'
export const WHITELIST_FAIL = 'Unable to whitelist user(s)'
export const ACCESS_GRANT = 'Access granted'
export const ACCESS_DENY = 'Denied user Acess'
export const TRY_AGAIN = 'Please try again'
export const RESTRICTED_USERS = 'restrictedUsers'
export const PENDING_USERS = 'pendingUsers'
export const PENDING = 'pending'
export const ALLOW = 'allow'
export const PAGE_LIMIT_EXCEEDED = 'There is no data for this page'
export const NO_DATA_FOUND = 'No data found'
export const NO_EVENT_SELECTED = 'No event selected'
export const SOMETHING_WENT_WRONG = 'Something went wrong'
export const ERROR_WHITELIST_USERS =
  'Error while trying to whitelist a list of users'
export const ERROR_WHITELIST_A_USER = 'Error while trying to whitelist the user'
