import React, { useState, useEffect } from 'react'
import { Container, Card, CardHeader, CardFooter, Row } from 'shards-react'
import { Form, Input, FormGroup, Button } from 'reactstrap'
import * as shared from 'pericles-shared'
import AddEditAdminModal from '../../components/admin/AddEditAdminModal'
import PaginationHandler from '../../components/common/Pagination'
import PageTitle from '../../components/common/PageTitle'
import { search } from '../../utils/search'
const { useCollection, fuego } = shared
const limit = 25

const Admin = () => {
  const [showAddEditModal, setShowAddEditModal] = React.useState(false)
  const toggleAddEditModal = () => setShowAddEditModal(!showAddEditModal)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pending, setPending] = useState(false)
  const { data, mutate } = useCollection(`instanceProperties/default/admins/`, {
    refreshInterval: 1,
  })
  const [searchResults, setSearchResults] = useState(null)
  const [searchInput, setSearchInput] = useState('')

  const displayAddEditModal = () => {
    setShowAddEditModal(true)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onHandleSearch = (e) => {
    e.preventDefault()
    setPending(true)
    if (searchInput.length) {
      setSearchResults(search(searchInput, data))
    } else {
      setSearchResults(null)
    }
    setPending(false)
  }

  const paginate = async () => {
    if (!data?.length) return

    const ref = fuego.db.collection(`instanceProperties/default/admins/`, {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 1,
    })

    const startAfterDocument = await ref.doc(data[data.length - 1].id).get()

    const moreDocs = await ref
      .startAfter(startAfterDocument)
      .limit(limit)
      .get()
      .then((d) => {
        setIsLoading(false)
        const docs = []
        d.docs.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }))
        return docs
      })

    mutate((state) => [...state, ...moreDocs], false)
  }

  useEffect(() => {
    if (!data?.length) return

    if (data.length > limit * currentPage) {
      paginate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const updateData = () => {
    setCurrentPage(1)
  }

  
  return (
    <div>
      <AddEditAdminModal
        isOpen={showAddEditModal}
        toggle={toggleAddEditModal}
        update={updateData}
      />
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Admin"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>
        <Row noGutters>
          <h4>Grant Admin Access</h4>
          {!isLoading ? (
            <>
              <Button
                disabled={false}
                onClick={displayAddEditModal}
                className="mb-4 ml-2"
              >
                Add new User
              </Button>
              <Form className="ml-auto mr-0" inline onSubmit={onHandleSearch}>
                <FormGroup className="mb-4 mr-sm-2 mb-sm-4">
                  <Input
                    // disabled={domain.length}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    id="#email"
                    name="search"
                    placeholder="search"
                  />
                </FormGroup>
                <Button disabled={pending} className="mb-4">
                  Search
                </Button>
              </Form>
            </>
          ) : (
            <shared.Loader />
          )}
        </Row>
        <Row>
          <Card className="w-100 mb-4">
            <CardHeader className="border-bottom">
              <h6>Admin Users</h6>
            </CardHeader>
            <div className="card-body p-0 pb-3 text-center">
              <table className="table table-hover mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Email
                    </th>
                    <th scope="col" className="border-0">
                      Role
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && !searchResults
                    ? data
                        .slice(currentPage * limit - limit, currentPage * limit)
                        .map((user, i) => (
                          <tr key={user.id}>
                            <td>{currentPage * limit - limit + i + 1}</td>
                            <td>{user.email}</td>
                            <td>{user.accessLevel}</td>
                          </tr>
                        ))
                    : searchResults
                        .slice(currentPage * limit - limit, currentPage * limit)
                        .map((result, i) => (
                          <tr key={result.item.id}>
                            <td>{currentPage * limit - limit + i + 1}</td>
                            <td>{result.item.email}</td>
                            <td>{result.item.accessLevel}</td>
                          </tr>
                        ))}
                </tbody>
              </table>
            </div>
            <CardFooter>
              {!searchResults ? (
                <PaginationHandler
                  activePage={currentPage}
                  itemsCountPerPage={limit}
                  totalItemsCount={data.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              ) : (
                <PaginationHandler
                  activePage={currentPage}
                  itemsCountPerPage={limit}
                  totalItemsCount={searchResults.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              )}
            </CardFooter>
          </Card>
        </Row>
      </Container>
    </div>
  )
}


export default Admin
