/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import {
  Container,
  Row,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'shards-react'
import { Table, Form, Label, Input, FormGroup } from 'reactstrap'
import ReactFileReader from 'react-file-reader'
import * as auth from 'api/auth'
import * as shared from 'pericles-shared'
import PaginationHandler from 'components/common/Pagination'
import extractNumbers from 'utils/extract-numbers'
import AlertModal from '../../components/common/Modal'
import * as constants from '../../constants'

import PageTitle from '../../components/common/PageTitle'

const { useCollection } = shared

const RestrictUsers = () => {
  const [eventId, setEventId] = React.useState('Select an event')
  const { data, error } = shared.useCollection(`events/${eventId}/whitelist`, {
    listen: true,
    suspense: true,
  })

  const { data: events } = useCollection(
    'events',
    {
      listen: true,
    },
    {
      orderBy: 'eventStart',
    }
  )

  const usersPerPage = 10

  const [restrictedUsers, setRestrictedUsers] = React.useState([])
  const [eventSelected, setEventSelected] = React.useState(false)
  const [pendingAccessUsers, setPendingAccessUsers] = React.useState([])
  const [email, setEmail] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [domain, setDomain] = React.useState('')
  const [pendingCurrentPage, setPendingCurrentPage] = React.useState(1)
  const [restrictedCurrentPage, setRestrictedCurrentpage] = React.useState(1)
  const [showModal, setShowModal] = React.useState(false)
  const [modalDescription, setModalDescription] = React.useState('')
  const [modalTitle, setModalTitle] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const toggle = () => setShowModal(!showModal)
  const [open, setOpen] = React.useState(false)

  if (error) {
    console.log('err:', error)
  }

  React.useEffect(() => {
    if (data) {
      data.forEach((user) => {
        if (user.requestAccess) {
          if (!pendingAccessUsers.find((usr) => usr.email === user.email)) {
            // eslint-disable-next-line no-shadow
            setPendingAccessUsers((pendingAccessUsers) =>
              pendingAccessUsers.concat(user)
            )
          }
        }
        if (user.restrictAccess) {
          if (!restrictedUsers.find((usr) => usr.email === user.email)) {
            // eslint-disable-next-line no-shadow
            setRestrictedUsers((restrictedUsers) =>
              restrictedUsers.concat(user)
            )
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handlePageChange = (page, type) => {
    if (type === constants.RESTRICTED_USERS) {
      setRestrictedCurrentpage(page)
    }
    if (type === constants.PENDING_USERS) {
      setPendingCurrentPage(page)
    }
  }

  const updateUserLists = (userEmail) => {
    setPendingAccessUsers(
      pendingAccessUsers.filter((obj) => obj.email !== userEmail)
    )
    setRestrictedUsers(restrictedUsers.filter((obj) => obj.email !== userEmail))
  }

  const setModal = (title, description) => {
    setModalTitle(title)
    setModalDescription(description)
  }

  const handleDropdown = (id) => {
    setEventId(id)
    setEventSelected(true)
    setPendingAccessUsers([])
    setRestrictedUsers([])
  }

  const handleFiles = (files) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setIsLoading(true)
      const readerResult = reader.result
      auth
        .addUsersToWhitelist({
          eventId,
          users: readerResult.split('\n'),
          restrictAccess: false,
        })
        .then(() => {
          setModal(constants.SUCCESS, constants.WHITELIST_SUCCESS)
        })
        .catch(() => {
          setModal(constants.FAILED, constants.WHITELIST_FAIL)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    reader.readAsText(files[0])
  }

  const loader = () => {
    return isLoading ? (
      <shared.Loader />
    ) : (
      <ReactFileReader
        disabled={isLoading}
        handleFiles={handleFiles}
        fileTypes=".csv"
      >
        <Button disabled={isLoading || !eventSelected} type="button">
          Upload
        </Button>
      </ReactFileReader>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email.length) {
      auth
        .addUsersToWhitelist({
          eventId,
          user: { firstName, lastName, email },
          restrictAccess: false,
          individual: true,
        })
        .then(() => {
          updateUserLists(email)
          setModal(constants.SUCCESS, constants.WHITELIST_SUCCESS)
        })
        .catch(() => {
          setModal(constants.FAILED, constants.WHITELIST_FAIL)
        })
        .finally(() => {
          setShowModal(!showModal)
          setEmail('')
          setFirstName('')
          setLastName('')
        })
    } else {
      auth
        .whitelistDomainUsers(domain)
        .then(() => {
          setPendingAccessUsers(
            pendingAccessUsers.filter(
              (userEmail) => !userEmail.includes(domain)
            )
          )
          setRestrictedUsers(
            restrictedUsers.filter((userEmail) => !userEmail.includes(domain))
          )
          setDomain('')
          setModal(constants.SUCCESS, constants.WHITELIST_SUCCESS)
        })
        .catch(() => {
          setModal(constants.FAILED, constants.WHITELIST_FAIL)
        })
        .finally(() => {
          setShowModal(!showModal)
        })
    }
  }

  const onClickHandler = ({ user }, action, type) => {
    if (type === constants.PENDING) {
      if (action === constants.ALLOW) {
        auth
          .addUsersToWhitelist({
            eventId,
            user: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            },
            individual: true,
            restrictAccess: false,
          })
          .then(() => {
            updateUserLists(user.email)
            setModal(constants.SUCCESS, constants.ACCESS_GRANT)
          })
          .catch(() => {
            setModal(constants.FAILED, constants.TRY_AGAIN)
          })
      } else {
        auth
          .addUsersToWhitelist({
            eventId,
            user: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            },
            individual: true,
            restrictAccess: true,
            requestAccess: false,
          })
          .then(() => {
            setPendingAccessUsers(
              pendingAccessUsers.filter((userEmail) => userEmail !== user.email)
            )
            setModal(constants.SUCCESS, constants.ACCESS_DENY)
          })
          .catch(() => {
            setModal(constants.FAILED, constants.TRY_AGAIN)
          })
          .finally(() => {
            setShowModal(!showModal)
          })
      }
    } else {
      auth
        .addUsersToWhitelist({
          eventId,
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          },
          individual: true,
          restrictAccess: false,
        })
        .then(() => {
          updateUserLists(user.email)
          setModal(constants.SUCCESS, constants.ACCESS_GRANT)
        })
        .catch(() => {
          setModal(constants.FAILED, constants.TRY_AGAIN)
        })
        .finally(() => {
          setShowModal(!showModal)
        })
    }
  }

  const displayUsers = (users, type) => {
    let currentPage = ''
    if (type === constants.PENDING_USERS) {
      currentPage = pendingCurrentPage
    } else if (type === constants.RESTRICTED_USERS) {
      currentPage = restrictedCurrentPage
    }
    const lastIndex = currentPage * usersPerPage
    const firstIndex = lastIndex - usersPerPage
    const currentUsers = users.slice(firstIndex, lastIndex)

    return currentUsers.map((user, idx) => {
      if (type === constants.PENDING_USERS) {
        return (
          <tr key={idx}>
            <th scope="row">{lastIndex + idx + 1 - usersPerPage}</th>
            <td>{user.email}</td>
            <td>
              <Button
                onClick={() =>
                  onClickHandler({ user }, constants.ALLOW, constants.PENDING)
                }
                type="button"
              >
                Allow
              </Button>
            </td>
            <td>
              <Button
                theme="danger"
                onClick={() =>
                  onClickHandler({ user }, 'deny', constants.PENDING)
                }
                type="button"
              >
                Deny
              </Button>
            </td>
          </tr>
        )
      }
      return (
        <tr key={idx}>
          <th scope="row">{lastIndex + idx + 1 - usersPerPage}</th>
          <td>{user.email}</td>
          <td>
            <Button
              onClick={() => onClickHandler({ user }, constants.ALLOW)}
              type="button"
            >
              Allow
            </Button>
          </td>
        </tr>
      )
    })
  }

  return (
    <div>
      <AlertModal
        isOpen={showModal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Restrict Users"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>
        <div style={{ margin: '20px 10px' }}>
          <h4>Whitelist users:</h4>
          <Row noGutters>
            {events && (
              <Dropdown
                open={open}
                toggle={() => setOpen(!open)}
                group
                className="mb-4"
              >
                <Button>
                  {eventId && eventId !== 'Select an event'
                    ? `Event ${extractNumbers(eventId)[0]}`
                    : 'Select an event'}
                </Button>
                <DropdownToggle split />
                <DropdownMenu>
                  {events.map((e) => {
                    const isValidEvent = extractNumbers(e.id)[0] !== -1
                    if (isValidEvent) {
                      return (
                        <DropdownItem
                          key={e.id}
                          onClick={() => handleDropdown(e.id)}
                        >
                          Event {extractNumbers(e.id)[0]}
                        </DropdownItem>
                      )
                    }

                    return null
                  })}
                </DropdownMenu>
              </Dropdown>
            )}
          </Row>

          {eventId !== 'Select an event' && (
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-2 mr-sm-2">
                <Label for="firstName" className="mr-sm-2">
                  First Name:
                </Label>
                <Input
                  disabled={domain.length}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id="#firstName"
                  name="firstName"
                  placeholder="First Name"
                />
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2">
                <Label for="lastName" className="mr-sm-2">
                  Last Name:
                </Label>
                <Input
                  disabled={domain.length}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="#lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
              </FormGroup>
              <FormGroup className="mb-4 mr-sm-2">
                <Label for="email" className="mr-sm-2">
                  Email
                </Label>
                <Input
                  disabled={domain.length}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="#email"
                  type="email"
                  name="email"
                  placeholder="email"
                />
              </FormGroup>
              <span style={{ marginTop: '6px', fontSize: '20px' }}>OR</span>
              <FormGroup className="mb-2 mt-3 mr-sm-2">
                <Label for="domain" className="mr-sm-2">
                  Domain
                </Label>
                <Input
                  disabled={email.length}
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  name="domain"
                  id="domain"
                  placeholder="exampledomain.com"
                />
              </FormGroup>
              <Button
                disabled={
                  (!email.length && !domain.length) ||
                  (!domain.length &&
                    !email.length &&
                    !firstName.length &&
                    !lastName.length)
                }
              >
                Add
              </Button>
            </Form>
          )}
        </div>

        {eventId !== 'Select an event' && (
          <>
            <div style={{ margin: '10px 10px' }}>
              <h4>Bulk whitelist users:</h4>
              {loader()}
            </div>
            <hr />
            <div style={{ margin: '20px 10px' }}>
              <h3>Pending requests:</h3>
              {pendingAccessUsers.length ? (
                <>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Allow Access</th>
                        <th>Deny Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayUsers(
                        pendingAccessUsers,
                        constants.PENDING_USERS
                      )}
                    </tbody>
                  </Table>
                  <PaginationHandler
                    activePage={pendingCurrentPage}
                    itemsCountPerPage={usersPerPage}
                    totalItemsCount={pendingAccessUsers.length}
                    pageRangeDisplayed={5}
                    onChange={(page) =>
                      handlePageChange(page, constants.PENDING_USERS)
                    }
                  />
                </>
              ) : (
                <h4>No pending users</h4>
              )}
            </div>
            <hr />
            <div style={{ margin: '20px 10px' }}>
              <h3>Restricted Users:</h3>
              {restrictedUsers.length ? (
                <>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Allow Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayUsers(
                        restrictedUsers,
                        constants.RESTRICTED_USERS
                      )}
                    </tbody>
                  </Table>
                  <PaginationHandler
                    activePage={restrictedCurrentPage}
                    itemsCountPerPage={usersPerPage}
                    totalItemsCount={restrictedUsers.length}
                    pageRangeDisplayed={5}
                    onChange={(page) =>
                      handlePageChange(page, constants.RESTRICTED_USERS)
                    }
                  />
                </>
              ) : (
                <h4>No restricted users</h4>
              )}
            </div>
          </>
        )}
        <Row />
      </Container>
    </div>
  )
}

export default RestrictUsers
