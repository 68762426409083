import React, { useState } from 'react'
import {
  Container,
  Row,
  Dropdown,
  Button,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
} from 'shards-react'
import * as shared from 'pericles-shared'

import extractNumbers from 'utils/extract-numbers'
import { useCollection, useDocument, errorHandler } from 'pericles-shared'
import { updateChatStatus } from 'api/chat'
import PageTitle from '../../components/common/PageTitle'

const Chat = () => {
  const [open, setOpen] = useState(false)
  const [eventId, setEventId] = useState()
  const { data: events } = useCollection('events')
  const [status, setStatus] = useState('ready')
  const { data: event } = useDocument(`events/${eventId}`, { listen: true })

  const currentDropdownEventTitle = eventId
    ? `Event ${extractNumbers(eventId)[0]}`
    : 'Select an event'

  const showActivated = status === 'ready' && event.chatEnabled
  const showDeactivated = status === 'ready' && !event.chatEnabled

  const changeChatStatusHandler = async (showChat) => {
    setStatus('in_progress')
    try {
      await updateChatStatus({ eventId, showChat })
    } catch (error) {
      setStatus('error')
      errorHandler(error)
    }

    setTimeout(() => {
      setStatus('ready')
    }, 500)
  }

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Chat"
            subtitle="KP Thoughtcast"
            className="text-sm-left"
          />
        </Row>

        <Row noGutters>
          <Dropdown
            open={open}
            toggle={() => setOpen(!open)}
            group
            className="mb-4"
          >
            <Button>{currentDropdownEventTitle}</Button>
            <DropdownToggle split />
            <DropdownMenu>
              {events.map((e) => {
                const isValidEvent = extractNumbers(e.id)[0] !== -1
                if (isValidEvent) {
                  return (
                    <DropdownItem key={e.id} onClick={() => setEventId(e.id)}>
                      Event {extractNumbers(e.id)[0]}
                    </DropdownItem>
                  )
                }

                return null
              })}
            </DropdownMenu>
          </Dropdown>
        </Row>

        {!eventId ? (
          <p>No event selected</p>
        ) : (
          <Card
            style={{
              width: '320px',
              marginRight: '1.5rem',
              marginBottom: '1.5rem',
            }}
          >
            <CardBody className="p-4">
              <CardTitle
                style={{
                  marginBottom: '0',
                  fontSize: '16px',
                }}
              >
                Chat status:{' '}
                {status === 'in_progress' && (
                  <span className="in-progress-status">in progress</span>
                )}
                {status === 'error' ? (
                  <span className="error-status">error</span>
                ) : (
                  <>
                    {showActivated && (
                      <span className="activated-status">activated</span>
                    )}
                    {showDeactivated && (
                      <span className="deactivated-status">deactivated</span>
                    )}
                  </>
                )}
              </CardTitle>
            </CardBody>
            <CardBody className="p-4">
              {status === 'ready' && (
                <>
                  {showActivated ? (
                    <Button
                      theme="danger"
                      onClick={() => {
                        changeChatStatusHandler(false)
                      }}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      theme="success"
                      onClick={() => {
                        changeChatStatusHandler(true)
                      }}
                    >
                      Activate
                    </Button>
                  )}
                </>
              )}
              {status === 'in_progress' && <shared.Loader />}
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  )
}

export default Chat
