import React, { useState, useEffect } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import * as shared from 'pericles-shared'


const ProtectedRouteHoc = ({ component: Component, location, ...rest }) => {
  const user = shared.firebase.auth().currentUser
  const [state, setState] = useState('loading')
  const [accessLevel, setAccessLevel] = useState(null)

  useEffect(() => {
    ;(async function() {
      try {
        const claims = await shared.getUserClaims()
        setAccessLevel(claims.accessLevel)
        setState(claims.admin ? 'isAdmin' : 'isNotAdmin')
      } catch {
        setState('isNotAdmin')
      }
    })()
  }, [])

  if (!user) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  if (state === 'loading') {
    return <div>Loading..</div>
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (state === 'isAdmin' && accessLevel === 0) {
          return <Component {...props} />
        }
        if (state === 'isAdmin' && accessLevel !== 0) {
          if (location.pathname.split('/')[1] === 'registration') {
            return <Redirect to={{ pathname: '/registration' }} />
          }
          if (location.pathname.split('/')[1] === 'q-and-a')
            return <Redirect to={{ pathname: '/q-and-a' }} />
          return <Redirect to={{ pathname: '/registration' }} />
        }
        return <Redirect to={{ pathname: '/access_denied' }} />
      }}
    />
  )
}

export default withRouter(ProtectedRouteHoc)
